// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'

import iconsStore from './iconsStore'

//Project Access to user
import projects from './projects'

// Reducers for Project : Utility and Module : Summary
import UtilitySummaryMapsReducer from './UtilityProject/Summary/GoogleMapsData'
import UtilitySummaryOperationalStatisticsReducer from './UtilityProject/Summary/OperationalStatistics'
import UtilitySummaryPrepaidPostpaidCountReducer from './UtilityProject/Summary/PrepaidPostpaidCount'
import UtilitySummaryProjectAreaReducer from './UtilityProject/Summary/ProjectArea'

// Reducers for Project : Utility and Module : MDMS Flow
import UtilityMdmsFlowReducer from './UtilityProject/MDMS/mdmsflow'

// Reducers for Project : Utility and Module : MDMS Alerts Filter
import UtilityMdmsAlertsFilterReducer from './UtilityProject/MDMS/alertsFilter'

//Reducers for Project : Utility and Module : MDMS Hierarchy  : PSS
import UtilityMdmsPssAlertsReducer from './UtilityProject/MDMS/pss/alerts'
import UtilityMdmsPssMeterAlertsReducer from './UtilityProject/MDMS/pss/meteralerts'
import UtilityMdmsPssBillsGeneratedReducer from './UtilityProject/MDMS/pss/billsgenerated'
import UtilityMdmsPssEnergyConsumptionReducer from './UtilityProject/MDMS/pss/energyconsumption'
import UtilityMdmsPssOperationalStatisticsReducer from './UtilityProject/MDMS/pss/operationalstatistics'
import UtilityMdmsPssUptimeReducer from './UtilityProject/MDMS/pss/uptime'

//Reducers for Project : Utility and Module : MDMS Hierarchy  : Feeder
import UtilityMdmsFeederAlertsReducer from './UtilityProject/MDMS/feeder/alerts'
import UtilityMdmsFeederMeterAlertsReducer from './UtilityProject/MDMS/feeder/meteralerts'
import UtilityMdmsFeederBillsGeneratedReducer from './UtilityProject/MDMS/feeder/billsgenerated'
import UtilityMdmsFeederEnergyConsumptionReducer from './UtilityProject/MDMS/feeder/energyconsumption'
import UtilityMdmsFeederOperationalStatisticsReducer from './UtilityProject/MDMS/feeder/operationalstatistics'
import UtilityMdmsFeederUptimeReducer from './UtilityProject/MDMS/feeder/uptime'

//Reducers for Project : Utility and Module : MDMS Hierarchy  : DTR
import UtilityMdmsDtrAlertsReducer from './UtilityProject/MDMS/dtr/alerts'
import UtilityMdmsDtrMeterAlertsReducer from './UtilityProject/MDMS/dtr/meteralerts'
import UtilityMdmsDtrBillsGeneratedReducer from './UtilityProject/MDMS/dtr/billsgenerated'
import UtilityMdmsDtrEnergyConsumptionReducer from './UtilityProject/MDMS/dtr/energyconsumption'
import UtilityMdmsDtrOperationalStatisticsReducer from './UtilityProject/MDMS/dtr/operationalstatistics'
import UtilityMdmsDtrUptimeReducer from './UtilityProject/MDMS/dtr/uptime'
import UtilityMdmsDtrSLAInformationReducer from './UtilityProject/MDMS/dtr/slainformation'

//Reducers for Project : Utility and Module : MDMS Hierarchy  : User
import UtilityMdmsUserAlertsReducer from './UtilityProject/MDMS/user/alerts'
import UtilityMdmsUserMeterAlertsReducer from './UtilityProject/MDMS/user/meteralerts'
import UtilityMdmsUserBillsGeneratedReducer from './UtilityProject/MDMS/user/billsgenerated'
import UtilityMdmsUserEnergyConsumptionReducer from './UtilityProject/MDMS/user/energyconsumption'
import UtilityMdmsUserOperationalStatisticsReducer from './UtilityProject/MDMS/user/operationalstatistics'
import UtilityMdmsUserUptimeReducer from './UtilityProject/MDMS/user/uptime'

//Reducer for Project: Utility and Module: MDMS and Hierarchy : User Profile
import UtilityMdmsConsumerCommandInfoReducer from './UtilityProject/MDMS/userprofile/consumercommandinfo'
import UtilityMdmsConsumerProfileInformationReducer from './UtilityProject/MDMS/userprofile/consumerinformation'
import UtilityMdmsConsumerTopAlertsReducer from './UtilityProject/MDMS/userprofile/consumertopalerts'
import UtilityMdmsConsumerTopMeterAlertsReducer from './UtilityProject/MDMS/userprofile/consumertopmeteralerts'
import UtilityMdmsConsumerConsumptionReducer from './UtilityProject/MDMS/userprofile/consumertotalconsumption'
import UtilityMdmsConsumerTotalRechargeReducer from './UtilityProject/MDMS/userprofile/consumertotalrecharges'
import UtilityMdmsConsumerPrepaidLedgerReducer from './UtilityProject/MDMS/userprofile/consumerPrepaidLedger'

//Reducer for Hierarchy Progress in MDMS
import UtilityMDMSHierarchyProgressReducer from './UtilityProject/MDMS/HierarchyProgress'

//Reducer for Month Selected
import calendarReducer from './navbar/calendar'

//Reducer for GIS Module Assets Data
import UtilityGISMapsReducer from './UtilityProject/GIS/MapData'
import UtilityGISMapsCenterReducer from './UtilityProject/GIS/MapCenter'
import UtilityGISMapsMarkerReducer from './UtilityProject/GIS/MapMarkers'
import UtilityGISMapsPolyLineReducer from './UtilityProject/GIS/MapPolyline'
import UtilityGISMapsZoomReducer from './UtilityProject/GIS/MapZoom'
import UtilityGISFilterReducer from './UtilityProject/GIS/MapFilterData'
import UtilityGISFilterSelectedReducer from './UtilityProject/GIS/MapFilterSelected'
import UtilityGISDTRFilterReducer from './UtilityProject/GIS/MapFilterDTRSelected'
import UtilityGISMapsUpdatedCenterReducer from './UtilityProject/GIS/UpdateMapCenter'

// REducer for MDAS Module
import UtilityMDASAssetListReducer from './UtilityProject/MDAS/MDASAssetList'
import UtilityMDASDlmsCommandReducer from './UtilityProject/MDAS/DLMSCommandList'
import UtilityMDASTapCommandReducer from './UtilityProject/MDAS/TAPCommandList'
import UtilityMDASCommandHistoryWithFilterReducer from './UtilityProject/MDAS/MDASCommandHistory'

// Reducer for Think Gas MDMS Module
import ThinkGasProjectInfoReducer from './UtilityProject/ThinkGasMdms/ProjectInfo'
import ThinkGasApplicantApprovalPendingReducer from './UtilityProject/ThinkGasMdms/ApplicantApprovalPending'
import ThinkGasApplicantApprovedReducer from './UtilityProject/ThinkGasMdms/ApplicantApproved'
import ThinkGasMeterInstalledReducer from './UtilityProject/ThinkGasMdms/MeterInstalled'
import ThinkGasMeterReadingReducer from './UtilityProject/ThinkGasMdms/MeterReading'
import ThinkGasPaymentHistoryReducer from './UtilityProject/ThinkGasMdms/PaymentHistory'
import ThinkGasConsumerInfoReducer from './UtilityProject/ThinkGasMdms/ConsumerInfo'
import ThinkGasMeterInfoReducer from './UtilityProject/ThinkGasMdms/MeterInfo'
import BillAnalysisReducer from './UtilityProject/coliving/billAnalysis'

// Search Option Visibility Reducer
import SearchOptionVisibilityReducer from './navbar/showSearchOption'

// Currently Selected Module Reducer
import CurrentSelectedModuleStatusReducer from './Misc/currentSelectedModuleStatus'

import analyticsReducer from './coliving/analytics'

const appReducer = combineReducers({
  auth,
  navbar,
  layout,

  //Icon Store
  iconsStore,

  //Project Access to user
  projects,

  //Reducers for Project : Utility and Module : Summary
  UtilitySummaryMapsReducer,
  UtilitySummaryPrepaidPostpaidCountReducer,
  UtilitySummaryOperationalStatisticsReducer,
  UtilitySummaryProjectAreaReducer,

  //Reducers for Project : Utility and Module : MDMS Hierarchy  : PSS
  UtilityMdmsPssAlertsReducer,
  UtilityMdmsPssMeterAlertsReducer,
  UtilityMdmsPssBillsGeneratedReducer,
  UtilityMdmsPssEnergyConsumptionReducer,
  UtilityMdmsPssOperationalStatisticsReducer,
  UtilityMdmsPssUptimeReducer,

  //Reducers for Project : Utility and Module : MDMS Hierarchy  : DTR
  UtilityMdmsDtrAlertsReducer,
  UtilityMdmsDtrMeterAlertsReducer,
  UtilityMdmsDtrBillsGeneratedReducer,
  UtilityMdmsDtrEnergyConsumptionReducer,
  UtilityMdmsDtrOperationalStatisticsReducer,
  UtilityMdmsDtrSLAInformationReducer,
  UtilityMdmsDtrUptimeReducer,

  //Reducers for Project : Utility and Module : MDMS Hierarchy  : Feeder
  UtilityMdmsFeederAlertsReducer,
  UtilityMdmsFeederMeterAlertsReducer,
  UtilityMdmsFeederBillsGeneratedReducer,
  UtilityMdmsFeederEnergyConsumptionReducer,
  UtilityMdmsFeederOperationalStatisticsReducer,
  UtilityMdmsFeederUptimeReducer,

  //Reducers for Project : Utility and Module : MDMS Hierarchy  : User
  UtilityMdmsUserAlertsReducer,
  UtilityMdmsUserMeterAlertsReducer,
  UtilityMdmsUserBillsGeneratedReducer,
  UtilityMdmsUserEnergyConsumptionReducer,
  UtilityMdmsUserOperationalStatisticsReducer,
  UtilityMdmsUserUptimeReducer,

  //Reducers for project : Utility , Module : MDMS and Hierarchy : User Profile
  UtilityMdmsConsumerCommandInfoReducer,
  UtilityMdmsConsumerProfileInformationReducer,
  UtilityMdmsConsumerTopAlertsReducer,
  UtilityMdmsConsumerTopMeterAlertsReducer,
  UtilityMdmsConsumerConsumptionReducer,
  UtilityMdmsConsumerTotalRechargeReducer,
  UtilityMdmsConsumerPrepaidLedgerReducer,

  //Reducer for Hierarchy Progress in MDMS
  UtilityMDMSHierarchyProgressReducer,

  // Reducer for MDMS Flow
  UtilityMdmsFlowReducer,

  // Reducer for Alerts Filter
  UtilityMdmsAlertsFilterReducer,

  //Calendar Month Selected reducer
  calendarReducer,

  //GIS Module Reducer
  UtilityGISMapsReducer,
  UtilityGISMapsCenterReducer,
  UtilityGISMapsMarkerReducer,
  UtilityGISMapsPolyLineReducer,
  UtilityGISMapsZoomReducer,
  UtilityGISFilterReducer,
  UtilityGISFilterSelectedReducer,
  UtilityGISDTRFilterReducer,

  // MDAS Module REducer
  UtilityMDASAssetListReducer,
  UtilityMDASDlmsCommandReducer,
  UtilityMDASTapCommandReducer,
  UtilityMDASCommandHistoryWithFilterReducer,
  UtilityGISMapsUpdatedCenterReducer,

  // Think Gas MDMS Reducer
  ThinkGasProjectInfoReducer,
  ThinkGasApplicantApprovalPendingReducer,
  ThinkGasApplicantApprovedReducer,
  ThinkGasMeterInstalledReducer,
  ThinkGasMeterReadingReducer,
  ThinkGasPaymentHistoryReducer,
  ThinkGasConsumerInfoReducer,
  ThinkGasMeterInfoReducer,

  // Bill analysis reducer
  BillAnalysisReducer,

  // Search Option Reducer
  SearchOptionVisibilityReducer,

  //Currently selected module reducer
  CurrentSelectedModuleStatusReducer,

  analyticsReducer
})

const rootReducer = (state, action) => {
  // If New Month Selected Reset all Data except defined below
  if (action.type === 'NewMonthSelected') {
    return {
      calendarReducer: state.calendarReducer,
      UtilityMDMSHierarchyProgressReducer: state.UtilityMDMSHierarchyProgressReducer,
      auth: state.auth,
      navbar: state.navbar,
      layout: state.layout,
      iconsStore: state.iconsStore,
      projects: state.projects
    }
  } else if (action.type === 'NewProjectSelected') {
    return {
      calendarReducer: state.calendarReducer,
      UtilityMDMSHierarchyProgressReducer: state.UtilityMDMSHierarchyProgressReducer,
      auth: state.auth,
      navbar: state.navbar,
      layout: state.layout,
      iconsStore: state.iconsStore,
      projects: state.projects
    }
  } else if (action.type === 'Logout') {
    return appReducer({ auth: state.auth, }, action)
  }

  return appReducer(state, action)
}

export default rootReducer
