const initialState = {
  colors: ['light-primary', 'light-info', 'light-danger', 'light-success', 'light-warning', 'light-primary', 'light-secondary'],
  icons: [
    'TrendingUp',
    'Cpu',
    'Download',
    'ZapOff',
    'Calendar',
    'Check',
    'ChevronRight',
    'ChevronLeft',
    'Clock',
    'Bell',
    'User',
    'Grid',
    'Activity',
    'RefreshCw',
    'MapPin',
    'Sliders',
    'Settings',
    'Unlock',
    'Circle',
    'Award'
  ],
  callAPI: true
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UpdateIconList':
      return {
        ...state,
        icons: action.data,
        callAPI: action.callAPI
      }
    default:
      return state
  }
}

export default authReducer
