// **  Initial State
const initialState = {
  siteList: []
}

const analyticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SITE_LIST':
      return {
        ...state,
        siteList: action.data
      }
    default:
      return state
  }
}

export default analyticsReducer