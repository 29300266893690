// **  Initial State
const initialState = {
  responseData: { asset_type: undefined, asset_name: undefined, command_name: undefined, command_status: undefined },
  callAPI: false
}

const UtilityMDASCommandHistoryWithFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'MDASCommandHistoryWithFilter':
      return {
        ...state,
        responseData: action.data,
        callAPI: action.callAPI
      }
    default:
      return state
  }
}

export default UtilityMDASCommandHistoryWithFilterReducer
