// **  Initial State
const initialState = {
    responseData: [],
    callAPI: true
}

const UtilityGISMapsCenterReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'UtilityGISMapsInitCenterData':
            return {
                ...state,
                responseData: action.data,
                callAPI: action.callAPI
            }
        default:
            return state
    }
}

export default UtilityGISMapsCenterReducer