// **  Initial State
const initialState = {
  responseData: {},
  centerUpdated: false
}

const UtilityGISMapsUpdatedCenterReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'handleGISMapUpdatedCenter':
      return {
        ...state,
        responseData: action.data,
        centerUpdated: action.centerUpdated
      }
    default:
      return state
  }
}

export default UtilityGISMapsUpdatedCenterReducer
