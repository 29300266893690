// **  Initial State
const initialState = {
  responseData: {
    vertical: undefined,
    project: undefined,
    module: undefined,
    prev_vertical: undefined,
    prev_project: undefined,
    prev_module: undefined
  },
  callAPI: true
}

const CurrentSelectedModuleStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CurrentSelectedModuleStatus':
      return {
        ...state,
        responseData: action.data,
        callAPI: action.callAPI
      }
    default:
      return state
  }
}

export default CurrentSelectedModuleStatusReducer
