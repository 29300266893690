// **  Initial State
const initialState = {
    responseData: {pss_list:[], feeder_list:[], dtr_list:[]},
    callAPI: true
}

const UtilityMDASAssetListReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'MDASAssetList':
            return {
                ...state,
                responseData: action.data,
                callAPI: action.callAPI
            }
        default:
            return state
    }
}

export default UtilityMDASAssetListReducer