// **  Initial State
const initialState = {
  showSearchBar: false
}

const SearchOptionVisibilityReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SearchOptionVisibility':
      return {
        ...state,
        showSearchBar: action.showSearchBar
      }
    default:
      return state
  }
}

export default SearchOptionVisibilityReducer
