import { Ability } from '@casl/ability'
import { initialAbility } from './initialAbility'
import jwt_decode from 'jwt-decode'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this

// const userData = JSON.parse(localStorage.getItem('userData'))
const userData = localStorage.getItem('accessToken') ? jwt_decode(localStorage.getItem('accessToken')).userData : null

const existingAbility = userData ? userData.ability : null

export default new Ability(existingAbility || initialAbility)
