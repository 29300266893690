const initialState = {
    responseData: 13,
    callAPI: true
}

const UtilityGISMapsZoomReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'handleGISMapZoom':
            return {
                ...state,
                responseData: action.data,
                callAPI: action.callAPI
            }
        default:
            return state
    }
}

export default UtilityGISMapsZoomReducer