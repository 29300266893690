// **  Initial State
const initialState = {
  userData: {},
  publicApiKey: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        userData: action.data,
        [action.config.storageTokenKeyName]:
          action[action.config.storageTokenKeyName],
        [action.config.storageRefreshTokenKeyName]:
          action[action.config.storageRefreshTokenKeyName],
      };
    case "LOGOUT":
      const { type, ...rest } = action;
      const obj = { ...state, ...rest, userData: {} };
      return obj;
    case "SET_PUBLIC_API_KEY":
      return { ...state, publicApiKey: action.payload };
    default:
      return state;
  }
};

export default authReducer;
