// **  Initial State
const initialState = {
    month: null,
    monthUpdated: false
}

const calendarReducer = (state = initialState, action) => {
    switch (action.type) {
        case "MonthUpdated":
            return {
                ...state,
                month: action.month,
                monthUpdated: action.monthUpdated
            }
        default:
            return state
    }
}

export default calendarReducer
